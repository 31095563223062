export class LastExtractedData {
  lastExtractDate: string;
  dealerships: Array<Dealership>;
  errorMessage: string;
  isSuccessfulCall: boolean;
  constructor() {
    this.dealerships = [];
  }
}
export class Dealership {
  dealershipName: string;
  lastExtractDate: string;
}
