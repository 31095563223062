import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../node_modules/rxjs/Observable';
import { Vehicle } from '../_models/index';
import { appConfig } from '../app.config';


@Injectable()
export class VehicleService {
  constructor(private http: HttpClient) { }
  FindVehicle(vin: string): Observable<Vehicle> {
    const queryString = appConfig.apiUrl + 'api/vehicle/FindVehicle/' + vin;
    return this.http.get<Vehicle>(queryString);
  }
}
