import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { ChangePasswordComponent } from './changepassword/index';
import { ReportDetailComponent, ReportSummaryComponent } from './report/index';
import { AuthGuard } from './_guards/index';
import { ForgotPasswordComponent, ShowPasswordComponent } from './forgotpassword/index';
import { DealComponent } from './deal/index';
import { UserComponent } from './user/index';
import { VinComponent } from './Vin/vin.component';
import { ResetPasswordComponent } from './resetpassword/index'

export const appRoutes: Routes = [
  { path: 'Home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'ChangePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'DetailReport', component: ReportDetailComponent, canActivate: [AuthGuard] },
  { path: 'SummaryReport', component: ReportSummaryComponent, canActivate: [AuthGuard] },
  { path: 'Deal', component: DealComponent, canActivate: [AuthGuard] },
  { path: 'User', component: UserComponent, canActivate: [AuthGuard] },
  { path: 'Vin', component: VinComponent, canActivate: [AuthGuard]},
  { path: 'Login', component: LoginComponent },
  { path: 'ForgotPassword', component: ForgotPasswordComponent },
  { path: 'ShowPassword', component: ShowPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },


  // otherwise redirect to home
  { path: '**', redirectTo: 'Home' }
  ];
export const AppRouting = RouterModule.forRoot(appRoutes);
export const routedComponents = [
  HomeComponent,
  LoginComponent,
  ChangePasswordComponent,
  ReportDetailComponent,
  ReportSummaryComponent,
  ForgotPasswordComponent,
  ShowPasswordComponent,
  DealComponent,
  UserComponent,
  VinComponent,
  ResetPasswordComponent
];

