import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../node_modules/rxjs/Observable';
import { appConfig } from '../app.config';
import { Group } from '../_models/index';

@Injectable()
export class GroupService {
  constructor(private http: HttpClient) { }

  GetGroup(_id: string, callSummary: boolean): Observable<Group> {
    return this.http.get<Group>(appConfig.apiUrl + 'api/group/getgroup/' + _id + '/' + callSummary);
  }

}
