import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';

import {   DealService, GroupService, ErrorService } from '../_services/index';
import { User, Group, GroupReport, ReportEntity, Report, Deal } from '../_models/index';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-deal',
  templateUrl: 'deal.component.html',
  styleUrls: ['deal.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class DealComponent implements OnInit {

  @ViewChild('btnShowDealPopup', {static: false}) btnShowDealPopup: ElementRef;
  @ViewChild('btnCloseDealPopup', {static: false}) btnCloseDealPopup: ElementRef;

  @ViewChild('btnShowAlertPopup', {static: false}) btnShowAlertPopup: ElementRef;
  @ViewChild('btnCloseAlert' , {static: false}) btnCloseAlert: ElementRef;

  deals: Array<Deal>;
  deal: Deal;
  dealDate: Date;
  columnsToDisplay = ['dealNumber', 'stockNumber', 'dealDate', 'customerName', 'financeManager'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator , {static: false}) paginator: MatPaginator;


  currentUser: User;
  group: Group;
  groupReport: GroupReport;
  groupId: string;
  reportScope: string;
  entityId: string;
  reportId: string;
  beginDate: string;
  endDate: string;
  searchOption: string;
  dealNumber: string;
  stockNumber: string;
  alertMessage: string;




  constructor(private groupService: GroupService, private dealService: DealService
              , private errorService: ErrorService) {
  }

  ngOnInit() {
    try {
      this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      if (this.currentUser.userGroups !== null) {
        this.groupId = this.currentUser.userGroups[0].groupId;
      }
      this.group = new Group;
      this.groupReport = new GroupReport;
      this.deal = new Deal;
      this.searchOption = 'byDealNumber';
      this.loadGroup();
      this.deals = [];
      this.dataSource.paginator = this.paginator;
    } catch (error) {
      this.errorService.LogError('ngOnInit', 'Deal', error.message);
    }
  }

  loadGroup() {
    try {
      if (this.groupId !== undefined) {
        this.groupService.GetGroup(this.groupId, false).subscribe(group => {
          this.group = group as Group;
          this.groupReport = this.group.groupReports[0];
          this.reportScope = this.groupReport.reportScope;
          const reportEntity = new ReportEntity;
          const select = 'Select';
          reportEntity.entityId = select;
          reportEntity.entityDescription = select;
          this.groupReport.reportEntities.push(reportEntity);
          this.entityId = select;
          const report = new Report;
          report.reportId = select;
          report.reportDescription = select;
          this.groupReport.reports.push(report);
          this.reportId = select;
        },  error => {
          this.errorService.LogError('loadGroup', 'Deal', (error as HttpErrorResponse).message);
        });
      }
    } catch (error) {
      this.errorService.LogError('loadGroup', 'Deal', error.message);
    }
  }
  loadGroupReport() {
    try {
      if (this.reportScope !== undefined) {
        this.groupReport = this.group.groupReports.find(x => x.reportScope === this.reportScope);
      }
    } catch (error) {
      this.errorService.LogError('loadGroupReport', 'Deal', error.message);
    }
  }
  searchDeals() {
    try {
      const doSearch = (this.groupId !== undefined && this.reportScope !== undefined
        && (this.entityId !== undefined && this.entityId !== 'Select')
        && this.beginDate !== undefined && this.endDate !== undefined);
      if (this.searchOption === 'byDateRange') {
        if (doSearch) {
          this.dealService.GetDealList(this.groupId, this.entityId, this.beginDate, this.endDate
                                , this.currentUser.userId).subscribe(data => {
            this.deals = data as Deal[];
            this.dataSource = new MatTableDataSource<Deal>(this.deals);
            this.dataSource.paginator = this.paginator;
          }, (error) => {
            this.errorService.LogError('searchDeals -> getDealList', 'Deal', (error as HttpErrorResponse).message);
          });
        }
      } else {
        let type: string;
        let searchNumber: string;
        if (this.searchOption === 'byDealNumber') {
          type = 'R';
          searchNumber = this.dealNumber;
        } else {
          type = 'S';
          searchNumber = this.stockNumber;
        }
        this.dealService.GetDeal(type, this.entityId, searchNumber, this.currentUser.userId).subscribe(data => {
          this.deal = data as Deal;
          if (this.deal.errorMessage === 'No Deal found') {
            this.alertMessage = 'No Deal Found';
            this.btnShowAlertPopup.nativeElement.click();
          } else {
            this.dealDate = new Date(this.deal.dealDate);
            const eligible = + this.deal.eligible;
            this.deal.eligible = (eligible === 1 ? 'Y' : 'N');
            this.deal.dealDate = this.formatDateToString(new Date(this.deal.dealDate));
            this.deal.customerBirthDate = this.formatDateToString(new Date(this.deal.customerBirthDate));
            this.btnShowDealPopup.nativeElement.click();
          }
        }, error => {
          this.errorService.LogError('searchDeals -> getDeal', 'Deal', (error as HttpErrorResponse).message);
        });
      }
    } catch (error) {
      this.errorService.LogError('searchDeals', 'Deal', error.message);
    }

  }

  formatDateToString(date) {
    const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    const MM = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    const yyyy = date.getFullYear();
    return (yyyy + '-' + MM + '-' + dd   );
  }
  getDealDetail(dealNumber: any) {
    try {
      this.dealService.GetDeal('R', this.entityId, dealNumber, this.currentUser.userId).subscribe(data => {
        this.deal = data as Deal;
        if (this.deal.errorMessage === 'No Deal found') {
          this.alertMessage = 'No Deal Found';
          this.btnShowAlertPopup.nativeElement.click();
        } else {
          this.dealDate = new Date(this.deal.dealDate);
          const eligible = + this.deal.eligible;
          this.deal.eligible = (eligible === 1 ? 'Y' : 'N');
          this.deal.dealDate = this.formatDateToString(new Date(this.deal.dealDate));
          this.deal.customerBirthDate = this.formatDateToString(new Date(this.deal.customerBirthDate));
          this.btnShowDealPopup.nativeElement.click();
        }
      }, (error: HttpErrorResponse) => {
        this.errorService.LogError('getDealDetail', 'Deal', (error as HttpErrorResponse).message);
      });
    } catch (error) {
      this.errorService.LogError('getDealDetail', 'Deal', error.message);
    }
  }
  saveDeal() {
    try {
      this.deal.entityId = this.entityId;
      this.deal.userId = this.currentUser.userId;
      this.dealService.SaveDeal(this.deal).subscribe(data => {
        this.deal = new Deal;
          this.btnCloseDealPopup.nativeElement.click();
     }, error => {
       this.errorService.LogError('saveDeal', 'Deal', (error as HttpErrorResponse).message);
      });
    } catch (error) {
      this.errorService.LogError('saveDeal', 'Deal', error.message);
    }
  }

  AddDeal() {
    this.deal = new Deal();
    this.btnShowDealPopup.nativeElement.click();
  }
  CloseDealPopup(): void {
       this.btnCloseDealPopup.nativeElement.click();
  }
  CloseAlertPopup(): void {
    this.btnCloseAlert.nativeElement.click();
  }

  callAlert(message: any): void {
    alert(message);
  }
}
export interface PeriodicElement {
  dealNumber: string;
  stockNumber: string;
  dealDate: string;
  customerName: string;
  financeManager: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  ];
