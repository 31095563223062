declare var require: any;
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ErrorService, AuthenticationService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: 'forgotpassword.component.html',
  styleUrls: ['forgotpassword.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  model: any = {};
  returnUrl: string;
  loading: boolean;
  resetLinkSend = false;
  pass: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.authenticationService.PageTitle = 'Forgot Password';
  }
  forgotPassword() {
    this.loading = true;
    this.authenticationService.ForgotPassword(this.model.userId)
      .subscribe(data => {
        this.resetLinkSend = data as boolean;
      },
      error => {
        this.errorService.LogError('forgotPassword', 'ForgotPassword', (error as HttpErrorResponse).message);
      });
    this.loading = false;
  }
}
