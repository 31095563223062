export class Deal {
  dealerId: string;
  dealNumber: string;
  stockNumber: string;
  dealDate: string;
  customerName: string;
  financeManager: string;
  newUsedDemo: string;
  errorMessage: string;
  customerBirthDate: string;
  customerZipCode: string;
  yearMakeModel: string;

  salesManager: string;
  salesman: string;
  financeLender: string;
  financeType: string;
  finanaceReserve: string;
  amountFinanced: string;
  loanTerm: string;
  payment: string;
  vIN: string;
  financingLender: string;
  financeReserve: string;
  serviceContractPrice: string;
  serviceContractCommission: string;
  serviceContractTerm: string;
  serviceContractMiles: string;
  lifePremium: string;
  lifeCommission: string;
  aHPremium: string;
  aHCommission: string;
  gAPCommission: string;
  chemicalsCommission: string;
  etchCommission: string;
  alarmsCommission: string;
  serviceContractProvider: string;
  frontGross: string;
  downloadDate: string;
  downPayment: string;
  sellRate: string;
  buyRate: string;
  dealStatus: string;
  crcValue: string;
  eligible: string;
  updateDate: string;
  updateValue: string;
  updateUser: string;
  menuMaxID: string;
  maintenanceCommission: string;
  customerAddress: string;
  customerCity: string;
  customerState: string;
  odometer: string;
  titaniumCommission: string;
  roadHazardCommission: string;
  keysCommission: string;
  dentCommission: string;
  windshieldCommission: string;
  entryDate: string;
  tradeIns: string;
  tradeActualCashValue: string;
  tradeOdometer: string;
  financeCode: string;
  statSeq: string;
  nUDSeq: string;
  trade: string;
  entityId: string;
  userId: string;

}
