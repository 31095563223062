import { Component, OnInit } from '@angular/core';
import { ErrorService, AuthenticationService, ReportService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html'
})

export class HomeComponent implements OnInit {
  currentUser: any;
  lastExtractdata: any = {};
  today = Date.now();
  showGrid: boolean ;
  constructor(private reportService: ReportService, private errorService: ErrorService
            , private authenticationService: AuthenticationService) {
    this.currentUser = this.authenticationService.CurrentUser;
    this.authenticationService.PageTitle = 'Home';
    this.showGrid = false;
  }
  ngOnInit() {
    try {
      if (this.currentUser !== undefined || this.currentUser !== null) {
        this.showGrid = false;
        this.reportService.GetLastExtractedData(this.currentUser.userId).subscribe(data => {
          this.lastExtractdata = data;
          if (this.lastExtractdata.dealerships != null && this.lastExtractdata.dealerships.length > 0) {
            this.showGrid = true;
          }
        }, error => {
         // const  message = 'An exception occurred while processing the request';
          this.errorService.LogError('getLastExtractedData', 'Home', (error as HttpErrorResponse).message);
        });
      }
    } catch (error) {
      this.errorService.LogError('ngOnInit', 'Home', error.message);
    }
  }
}
