import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule  } from '@angular/common/http';
import {HttpModule} from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { TelerikReportingModule } from '@progress/telerik-angular-report-viewer';
import { AppRouting } from './routing.module';



import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

import { AuthGuard } from './_guards/index';
import {
  AuthenticationService, UserService
  , GroupService, ReportService, DealService, VehicleService, ErrorService
} from './_services/index';
import { HomeComponent } from './home/index';
import { LoginComponent } from './login/index';
import { DealComponent } from './deal/index';
import {UserComponent} from './user/user.component';

import { ReportDetailComponent, ReportSummaryComponent} from './report/index';
import { ChangePasswordComponent} from './changepassword/index';
import { ForgotPasswordComponent, ShowPasswordComponent } from './forgotpassword/index';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatCheckboxModule } from '@angular/material'
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { ErrorComponent } from './error/error.component';
import { VinComponent } from './Vin/vin.component';
import { ResetPasswordComponent } from './resetpassword/index'

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    LoginComponent,
    ChangePasswordComponent,
    ReportDetailComponent,
    ReportSummaryComponent,
    ForgotPasswordComponent,
    ShowPasswordComponent,

    DealComponent,
    ErrorComponent,
    UserComponent,
    VinComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AppRouting,
    TelerikReportingModule,
    BrowserAnimationsModule,
    A11yModule,
    CdkTableModule,
    CdkTreeModule,
    MatPaginatorModule,
    MatTableModule,
    MatSortModule,
    ScrollDispatchModule,
    MatFormFieldModule,
    MatInputModule,
    HttpModule,
    MatCheckboxModule,
  ],
  exports: [
    RouterModule,
    A11yModule,
    CdkTableModule,
    CdkTreeModule,
    MatPaginatorModule,
    MatSortModule,
    ScrollDispatchModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    UserService,
    GroupService,
    DealService,
    ReportService,
    ErrorService,
    VehicleService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
