import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../node_modules/rxjs/Observable';
import { User } from '../_models/index';
// import 'rxjs/Rx';

import { appConfig } from '../app.config';
import 'rxjs/add/operator/map';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()

export class AuthenticationService {

  constructor(private http: HttpClient) {
  }

  private currentUser: User;
  private pagetitle: string;
  public get CurrentUser(): User {
    if (sessionStorage.getItem('currentUser') != null) {
      this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    return this.currentUser;
  }

  Login(userId: string, password: string): Observable<any> {
    return this.http.post<any>(appConfig.apiUrl + 'api/user/authenticate', { userId: userId, password: password })
      .map(user => {
        if (user && user.loggedIn ) {
          sessionStorage.setItem('currentUser', JSON.stringify(user));
        }
        return user;
      });
  }
  ChangePassword(userId: string, password: string): Observable<any> {
    return this.http.post<any>(appConfig.apiUrl + 'api/user/changepassword', { userId: userId, password: password })
      .map(user => {
        return user;
      });
  }

  SavePassword(userId: string, password: string): Observable<any> {
    return this.http.post<any>(appConfig.apiUrl + 'api/user/savepassword', { userId: userId, password: password })
      .map(user => {
        return user;
      });
  }

  VerifyTempPassword(userId: string, password: string): Observable<any> {
    return this.http.post<any>(appConfig.apiUrl + 'api/user/authenticate', { userId: userId, password: password })
      .map(user => {
        return user;
      });
  }
  ForgotPassword(userId: string): Observable<any> {
    return this.http.get(appConfig.apiUrl + 'api/user/ForgotPassword/' + userId);
  }

  ShowPassword(userId: string) {
    return this.http.get(appConfig.apiUrl + 'api/user/showPassword/' + userId);
  }
  HashPassword( password: string) {
    return this.http.post<any>(appConfig.apiUrl + 'api/user/gethashpassword', {  password: password })
      .map(hashpassword => {
        return hashpassword;
      });
  }
  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
  }
  public loggedIn(): boolean {
    let login = false;
    if (sessionStorage.getItem('currentUser')) {
      login = true;
    }
    return login;
  }

  public get PageTitle(): string {
    return this.pagetitle;
  }
  public set PageTitle(value: string) {
    this.pagetitle = value;
  }
}
