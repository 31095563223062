export class Error {
    constructor() {}
    errorDate: string;
    errorTime: string;
    serverName: string;
    appName: string;
    routine: string;
    url: string;
    userId: string;
    page: string;
    details: string;
}
