import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../node_modules/rxjs/Observable';
import { appConfig } from '../app.config';

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) { }

  GetSummaryReport(reportGroupId: string, reportScope: string, reportEntityId: string,
                reportId: string, beginDate: string, endDate: string ): Observable<any> {
                  const querystring = appConfig.apiUrl + 'api/report/GetReportSummary/'
                  + reportGroupId + '/' + reportScope + '/' + reportEntityId + '/' + reportId + '/' + beginDate + '/' + endDate;
    return this.http.get<any>(querystring);
  }
  GetLastExtractedData(_id: string): Observable<any> {
    return this.http.get<any>(appConfig.apiUrl + 'api/report/GetLastExtractedData/' + _id);
  }
}
