import { Component, OnInit } from '@angular/core';
import { User } from '../_models/index';
import { ErrorService, UserService, AuthenticationService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-resetpassword',
  templateUrl: 'resetpassword.component.html',
  styleUrls: ['resetpassword.component.css'],
})


export class ResetPasswordComponent implements OnInit {

  user: User;
  newPassword: string ='';
  confirmPassword: string = '';
  userId: string;
  model: any = {};
  constructor(private userService: UserService, private authenticationService: AuthenticationService
    , private errorService: ErrorService, private aRouter: ActivatedRoute, private router: Router) {
    
  }

    ngOnInit(): void {
      this.user = new User;
      this.aRouter.queryParamMap.subscribe(params => {
        this.userId = params.get('userid');
        
      });
  }

  ResetPassword(): void {
    if (this.newPassword === this.confirmPassword) {
      this.authenticationService.ChangePassword(this.userId, this.newPassword)
        .subscribe(data => {
          this.router.navigate(['/Login']);
        },
          error => {
            this.errorService.LogError('ResetPassword', 'ChangePassword', (error as HttpErrorResponse).message);
          });
    }
    
  }

}
