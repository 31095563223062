declare var require: any;
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ErrorService, AuthenticationService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-showpassword',
  templateUrl: 'showpassword.component.html',
  styleUrls: ['showpassword.component.css']
})

export class ShowPasswordComponent implements OnInit {
  model: any = {};
  returnUrl: string;
  loading: boolean;
  resetLinkSend = false;
  pass: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.authenticationService.PageTitle = 'Show Encrypted Password';
  }

  showPassword() {
    this.loading = true;
    this.authenticationService.HashPassword(this.model.userId)
      .subscribe(data => {
        this.pass = data;
      },
      error => {
        this.errorService.LogError('showPassword', 'ShowPassword', (error as HttpErrorResponse).message);
      });
    this.loading = false;
  }
}
