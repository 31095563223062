declare var require: any;
import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { User } from '../_models/index';
import { AuthenticationService, UserService, ErrorService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;

  @ViewChild('btnShowUserPopup', {static: false}) btnShowUserPopup: ElementRef;
  @ViewChild('btnCloseUserPopup', {static: false}) btnCloseUserPopup: ElementRef;

  siteBanner = require('./AutoDealerReports.png');

  user: User;
  constructor( public authenticationService: AuthenticationService, public  userService: UserService
          , private errorService: ErrorService) {
  }
  ngOnInit() {
    this.user = new User();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  logout() {
      // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    location.reload();
    }
  getUser() {
    try {
      this.user = new User();
      this.userService.GetUserById(this.authenticationService.CurrentUser.userId).subscribe(u => {
        this.user = u as User;
        this.btnShowUserPopup.nativeElement.click();
      }, error => {
        this.errorService.LogError('getUserById', 'NavMenuComponent', (error as HttpErrorResponse).message);
      });
    } catch (error) {
      this.errorService.LogError('getUser', 'NavMenuComponent', error.message);
    }
  }
  saveUser(): void {
    try {
      this.userService.Save(this.user).subscribe(user => {
        this.btnCloseUserPopup.nativeElement.click();
      }, error => {
        this.errorService.LogError('Save', 'NavMenuComponent', (error as HttpErrorResponse).message);
      });
    } catch (error) {
      this.errorService.LogError('saveUser', 'NavMenuComponent', error.message);
    }
  }
  closeUserPopup(): void {
    this.btnCloseUserPopup.nativeElement.click();
  }
}
