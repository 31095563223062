export class Vehicle {
  constructor() {
    this.isFound = false;
    this.hasError = false;
  }
  vin: string;
  modelYear: number;
  checkDigit: string;
  make: string;
  model: string;
  bodyType: string;
  driveType: string;
  fuelType: string;
  turboType: string;
  gvwrClass: string;
  trim: string;
  engine: string;
  odometer: number;
  brakeSystem: string;
  vehicleClass: string;
  vehicleType: string;
  factory: string;
  country: string;
  assemblyPlant: string;
  productionSequenceNumber: string;
  isNew: boolean;
  errorMessage: string;
  isDecoded: boolean;
  licensePlate: string;
  manufacturer: string;
  engineCode: string;
  isFound: boolean;
  hasError: boolean;
}
