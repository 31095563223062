export class Group {
  groupId: string;
  groupName: string;
  groupReports: Array<GroupReport>;
  errorMessage: string;
  isSuccessfulCall: boolean;
  constructor() {
    this.groupReports = [];
  }
}
export class GroupReport {
  reportScope: string;
  reportEntities: Array<ReportEntity>;
  reports: Array<Report>;
  constructor() {
    this.reportEntities = [];
    this.reports = [];
  }
}
export class ReportEntity {
  entityId: string;
  entityDescription: string;
}
export class Report {
  reportId: string;
  reportDescription: string;
}
