import { Component, OnInit } from '@angular/core';
import { VehicleService, ErrorService } from '../_services/index';
import { Vehicle } from '../_models/index';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
    selector: 'app-vin',
    templateUrl: 'vin.component.html',
})
export class VinComponent implements OnInit {
    vehicle: Vehicle;
    vin: string;
    constructor(private vehicleService: VehicleService, private errorService: ErrorService) {
    }
  ngOnInit(): void {
    this.vehicle = new Vehicle();
    }
    findVehicle() {
      try {
        console.log(this.vin);
        this.vehicleService.FindVehicle(this.vin).subscribe(data => {
          this.vehicle = data;
          }, (error: HttpErrorResponse) => {
            this.errorService.LogError('findVehicle', 'Vehicle', (error as HttpErrorResponse).message);
          });
        } catch (error) {
          this.errorService.LogError('findVehicle', 'Vehicle', error.message);
        }
      }
}

