import { Component, HostBinding, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ErrorService } from '../_services/index';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  message: any;
  @ViewChild('btnShowPopup', {static: false}) btnShowPopup: ElementRef;
  @ViewChild('btnClose' , {static: false}) btnClose: ElementRef;

  constructor(public errorservice: ErrorService) { }

  ngOnInit() {
    this.errorservice.notify.subscribe( isNotify => {
      this.btnShowPopup.nativeElement.click();
    });
  }
  closePopup(): void {
    this.btnClose.nativeElement.click();
  }
}
