import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { User, Group, GroupReport, ReportEntity, Report } from '../_models/index';
import { UserService, GroupService, ReportService, AuthenticationService, ErrorService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-summaryreport',
  templateUrl: './reportsummary.component.html',
  styles: [
    'div#content   {       height: 100 %;     }'
  ]
})
export class ReportSummaryComponent implements OnInit  {

  @ViewChild('viewer1', {static: false}) viewer;

  collapse: boolean;
  currentUser: User;
  group: Group;
  groupReport: GroupReport;
  groupId: string;
  reportScope: string;
  entityId: string;
  reportId: string;
  beginDate: string;
  endDate: string;
  groupDescription: string;
  entityDescription: string;

  serverId: string;
  clientIP: string;

  title = 'Report Viewer';
  viewerContainerStyle = {
    position: 'relative',
    top: '5px',
    bottom: '5px',
    left: '5px',
    right: '5px',
    overflow: 'auto',
    ['font-family']: 'ms sans serif',
    height: '100vh'

  };

  ready() { console.log('ready'); }
  viewerToolTipOpening(e: any, args: any) { console.log('viewerToolTipOpening ' + args.toolTip.text); }

  constructor(private userService: UserService, private groupService: GroupService,
              private reportService: ReportService, private authenticationService: AuthenticationService,
              private errorService: ErrorService) {

  }

  ngOnInit() {
    try {
      this.collapse = false;
      this.authenticationService.PageTitle = 'Summary Report';
      this.currentUser = this.authenticationService.CurrentUser;
      if (this.currentUser.userGroups !== null) {
        this.groupId = this.currentUser.userGroups[0].groupId;
      }
      this.group = new Group;
      this.groupReport = new GroupReport;
      this.loadGroup();
    } catch (error) {
      this.errorService.LogError('ngOnInit', 'SummaryReport', error.message);
    }
  }
  loadGroup() {
    try {
      if (this.groupId !== undefined) {
        this.groupService.GetGroup(this.groupId, true).subscribe(group => {
          this.group = group as Group;
          this.groupReport = this.group.groupReports[0];
          this.reportScope = this.groupReport.reportScope;
          const reportEntity = new ReportEntity;
          const select = 'Select';
          reportEntity.entityId = select;
          reportEntity.entityDescription = select;
          this.groupReport.reportEntities.push(reportEntity);
          this.entityId = select;
          const report = new Report;
          report.reportId = select;
          report.reportDescription = select;
          this.groupReport.reports.push(report);
          this.reportId = select;

          this.userService.GetUserIp().subscribe(ip => {
            this.clientIP = ip;
            this.userService.GetServerId().subscribe(sId => {
              this.serverId = sId;
            });
          });

        }, error => {
          const  message = 'An  exception occurred while processing the request';
          this.errorService.LogError('getgroup', 'SummaryReport', (error as HttpErrorResponse).message);
        });
      }
    } catch (error) {
      this.errorService.LogError('loadGroup', 'SummaryReport', error.message);
    }

  }
  loadGroupReport() {
    try {
      if (this.reportScope !== undefined) {
        this.groupReport = this.group.groupReports.find(x => x.reportScope === this.reportScope);
        const reportEntity = new ReportEntity;
        const select = 'Select';
        reportEntity.entityId = select;
        reportEntity.entityDescription = select;
        reportEntity.entityId = select;
        reportEntity.entityDescription = select;
        this.groupReport.reportEntities.push(reportEntity);
        this.entityId = select;
        const report = new Report;
        report.reportId = select;
        report.reportDescription = select;
        this.groupReport.reports.push(report);
        this.reportId = select;
      }
    } catch (error) {
      this.errorService.LogError('loadGroupReport', 'SummaryReport', error.message);
    }
  }

  transform(value: string) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'MM/dd/yyyy');
    return value;
  }

  GenerateSummaryReport(): void {
    try {
      const displayReport = (this.groupId !== undefined && this.reportScope !== undefined
        && (this.entityId !== undefined && this.entityId !== 'Select')
        && (this.reportId !== undefined && this.reportId !== 'Select')
        && this.beginDate !== undefined && this.endDate !== undefined);

      if (displayReport) {
        this.collapse = true;
        const lBeginDate = this.transform(this.beginDate);
        const lEndDate = this.transform(this.endDate);
        this.groupDescription = this.group.groupName;
        this.entityDescription = this.groupReport.reportEntities.find(e => e.entityId === this.entityId).entityDescription;
        const rs = {
          report: 'TestReportRestService.ReportSummary, TestReportRestService, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null',
          parameters: {
            ReportGroupId: this.groupId,
            ReportScope: this.reportScope,
            ReportEntityId: this.entityId,
            ReportId: this.reportId,
            BeginDate: lBeginDate,
            EndDate: lEndDate,
            CallSummary: true,
            GroupDescription: this.groupDescription,
            EntityDescription: this.entityDescription,
            UserID: this.currentUser.userId,
            ServerID: this.serverId,
            ClientIP: this.clientIP
          }
        };
        this.viewer.setReportSource(rs);
      }
    } catch (error) {
      this.errorService.LogError('showReport', 'SummaryReport', error.message);
    }
  }
  showParameterPanel() {
    this.collapse = false;
  }

}
