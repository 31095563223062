import { Group } from './index';

export class User {
  userId: string;
  password: string;
  confirmPassword: string;
  visitCount: number;
  lastLoginDate: string;
  regCode: string;
  lastName: string;
  firstName: string;
  title: string;
  company: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  email: string;
  profileId: string;
  roleId: string;

  loggedIn: boolean;
  userGroups: Array<Group>;
  errorMessage: string;
  isSuccessfulCall: boolean;
  isActive: boolean;
  isAdmin: boolean;
  constructor() {
    this.userGroups = [];
  }
}

export class MVUser {
  userId: string;
  lastName: string;
  firstName: string;
  email: string;
}
