
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorService, AuthenticationService} from '../_services/index';
import { User } from '../_models/index';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-changepassword',
  templateUrl: 'changepassword.component.html'
})
export class ChangePasswordComponent implements OnInit {
  model: any = {};
  currentUser: User;
  isOldPasswordMatch: boolean;

  constructor(private router: Router, private authenticationService: AuthenticationService
            , private errorService: ErrorService) {
    this.currentUser = this.authenticationService.CurrentUser;
    this.authenticationService.PageTitle = 'Change Password';

  }
  ngOnInit(): void {

  }
  ConfirmPass() {
    this.authenticationService.HashPassword(this.model.password)
      .subscribe(data => {
        if (this.currentUser.password === data) {
          this.isOldPasswordMatch = true;
          this.ChangePass();
        } else {
          this.isOldPasswordMatch = false;
        }
      },
      error => {
        this.errorService.LogError('ConfirmPass', 'ChangePassword', (error as HttpErrorResponse).message);
      });
  }
  ChangePass() {
    if (this.model.newPassword === this.model.confirmPassword) {
      this.authenticationService.ChangePassword(this.currentUser.userId, this.model.newPassword)
        .subscribe(data => {
          this.authenticationService.logout();
          this.router.navigate(['/Login']);
        },
        error => {
          this.errorService.LogError('ChangePass', 'ChangePassword', (error as HttpErrorResponse).message);
        });
    }
  }
}

