import { Component, OnInit, ViewChild } from '@angular/core';
// import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { User, Group, GroupReport, ReportEntity, Report } from '../_models/index';
// import { TelerikReportViewerComponent } from '@progress/telerik-angular-report-viewer';
import { UserService, GroupService, ReportService, AuthenticationService, ErrorService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-detailreprot',
  templateUrl: './reportdetail.component.html',
  styleUrls: ['./reportdetail.component.css']
  })

export class ReportDetailComponent implements OnInit {
  @ViewChild('viewer1', {static: false}) viewer;
  collapse: boolean;
  title = 'Report Viewer';

  currentUser: User;
  group: Group;
  groupReport: GroupReport;
  groupId: string;
  reportScope: string;
  entityId: string;
  reportId: string;
  groupDescription: string;
  entityDescription: string;

  beginDate: string;
  endDate: string;

  serverId: string;
  clientIP: string;

  viewerContainerStyle = {
    position: 'relative',
    top: '5px',
    bottom: '5px;',
    left: '5px',
    right: '5px',
    overflow: 'hidden',
    ['font-family']: 'ms sans serif',
    height: '100vh',
  };

  ready() { console.log('ready'); }

  viewerToolTipOpening(e: any, args: any) { console.log('viewerToolTipOpening ' + args.toolTip.text); }
  constructor(private userService: UserService, private groupService: GroupService,
               private authenticationService: AuthenticationService,
              private errorService: ErrorService) {
  }
  ngOnInit() {
    try {
      this.group = new Group;
      this.groupReport = new GroupReport;
      this.authenticationService.PageTitle = 'Detail Report';
      this.collapse = false;
      this.currentUser = this.authenticationService.CurrentUser;
      if (this.currentUser.userGroups !== null) {
        this.groupId = this.currentUser.userGroups[0].groupId;
      }
      this.loadGroup();
    } catch (error) {
      this.errorService.LogError('ngOnInit', 'DetailReport', error.message);
    }
  }

  loadGroup() {
    try {
      this.groupService.GetGroup(this.groupId, false).subscribe(group => {
        this.group = group as Group;
        this.groupReport = this.group.groupReports[0];
        const reportEntity = new ReportEntity;
        const select = 'Select';
        reportEntity.entityId = select;
        reportEntity.entityDescription = select;
        this.groupReport.reportEntities.push(reportEntity);
        this.entityId = select;
        const report = new Report;
        report.reportId = select;
        report.reportDescription = select;
        this.groupReport.reports.push(report);
        this.reportId = select;
        this.reportScope = this.groupReport.reportScope;
        this.userService.GetUserIp().subscribe(ip => {
          this.clientIP = ip;
          this.userService.GetServerId().subscribe(sId => {
            this.serverId = sId;
          });
        });
      }, error => {
        this.errorService.LogError('getgroup', 'DetailReport', (error as HttpErrorResponse).message);
        });
    } catch (error) {
      this.errorService.LogError('loadGroup', 'DetailReport', error.message);
    }
  }
  loadGroupReport() {
    try {
      this.groupReport = this.group.groupReports.find(x => x.reportScope === this.reportScope);
      const select = 'Select';
      const reportEntity = new ReportEntity;
      reportEntity.entityId = select;
      reportEntity.entityDescription = select;
      this.groupReport.reportEntities.push(reportEntity);
      this.entityId = select;
      const report = new Report;
      report.reportId = select;
      report.reportDescription = select;
      this.groupReport.reports.push(report);
      this.reportId = select;
    } catch (error) {
      this.errorService.LogError('loadGroupReport', 'DetailReport', error.message	);
    }
  }

  transform(value: string) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'MM/dd/yyyy');
    return value;
  }

  GenerateDetailReport(): void {
    try {
      const displayReport = (this.groupId !== undefined && this.reportScope !== undefined
        && (this.entityId !== undefined && this.entityId !== 'Select')
        && (this.reportId !== undefined && this.reportId !== 'Select')
        && this.beginDate !== undefined && this.endDate !== undefined);
      if (displayReport) {
        this.collapse = true;
        const lBeginDate = this.transform(this.beginDate);
        const lEndDate = this.transform(this.endDate);
        this.groupDescription = this.group.groupName;
        this.entityDescription = this.groupReport.reportEntities.find(e => e.entityId === this.entityId).entityDescription;
        const rs = {
          report: 'TestReportRestService.ReportSummary, TestReportRestService, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null',
          parameters: {
            ReportGroupId: this.groupId,
            ReportScope: this.reportScope,
            ReportEntityId: this.entityId,
            ReportId: this.reportId,
            BeginDate: lBeginDate,
            EndDate: lEndDate,
            CallSummary: false,
            GroupDescription: this.groupDescription,
            EntityDescription: this.entityDescription,
            UserID: this.currentUser.userId,
            ServerID: this.serverId,
            ClientIP: this.clientIP
          }
        };
        this.viewer.setReportSource(rs);
      }
    } catch (error) {
      this.errorService.LogError('showReport', 'DetailReport', error.message);
    }
  }
  showParameterPanel() {
    this.collapse = false;
  }
}
