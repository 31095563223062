import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { animate, state, style, transition, trigger } from '@angular/animations';

import { User } from '../_models/index';
import { ErrorService, UserService, AuthenticationService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.component.css']
})
export class UserComponent implements OnInit {


  @ViewChild('btnShowEditUserPopup', {static: false}) btnShowEditUserPopup: ElementRef;
  @ViewChild('btnCloseEditUserPopup', {static: false}) btnCloseEditUserPopup: ElementRef;

  @ViewChild('btnShowAlertPopup', {static: false}) btnShowAlertPopup: ElementRef;
  @ViewChild('btnCloseAlert', {static: false}) btnCloseAlert: ElementRef;

  @ViewChild('btnShowResetPopup', {static: false}) btnShowResetPopup: ElementRef;
  @ViewChild('btnCloseResetUserPopup', {static: false}) btnCloseResetUserPopup: ElementRef;

  user: User;
  users: Array<User>;
  alertMessage: string;
  pageSize: number;

  displayedColumns = ['userId', 'firstName', 'lastName', 'email','isActive','isAdmin', 'actions'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  model: any = {};
  constructor(private userService: UserService, private authenticationService: AuthenticationService
              , private errorService: ErrorService) {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    try {
      this.user = new User;
      this.pageSize = 10;
      this.GetUsers();
      this.dataSource.paginator = this.paginator;

    } catch (error) {
      this.errorService.LogError('ngOnInit', 'Deal', error.message);
    }
  }
  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.pageSize = this.paginator.pageSize;
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  EditUser(user): void {
    this.user = user;
    this.userService.GetUserById(user.userId).subscribe(u => {
      this.user = u as User;
    }, error => {
      this.errorService.LogError('getUserById', 'user', (error as HttpErrorResponse).message);
    });
   // console.log(user);
  this.btnShowEditUserPopup.nativeElement.click();
}
  GetUsers() {
    this.userService.GetUsers(this.authenticationService.CurrentUser.userId).subscribe(users => {
      this.users = users;

      this.dataSource = new MatTableDataSource<User>(this.users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.user = new User;
    });
  }

  SaveUser() {
    try {
      this.userService.Save(this.user).subscribe(user => {
        this.btnCloseEditUserPopup.nativeElement.click();
        this.alertMessage = 'User Information edited successfully';
        this.GetUsers();
        this.btnShowAlertPopup.nativeElement.click();
      }, error => {
        this.errorService.LogError('Save', 'NavMenuComponent', (error as HttpErrorResponse).message);
      });
    } catch (error) {
      this.errorService.LogError('saveUser', 'user', error.message);
    }
  }

  AddUser() {
    this.user = new User();
    this.btnShowEditUserPopup.nativeElement.click();
  }

  CloseEditUserPopup(): void {
    this.btnCloseEditUserPopup.nativeElement.click();
  }

  CloseAlertPopup(): void {
    this.btnCloseAlert.nativeElement.click();
  }
  ShowResetPassPopup(user): void {
    this.user = user;
    this.btnShowResetPopup.nativeElement.click();
  }
  CloseResetUserForm(): void {
    this.user = new User();
    this.btnCloseResetUserPopup.nativeElement.click();
  }

  ResetPassword(): void {
    if (this.user.password === this.user.confirmPassword) {
      this.authenticationService.ChangePassword(this.user.userId, this.user.password)
        .subscribe(data => {
          this.btnCloseResetUserPopup.nativeElement.click();
          this.alertMessage = 'Password reset successfully';
          this.btnShowAlertPopup.nativeElement.click();
        },
          error => {
            this.errorService.LogError('ResetPassword', 'ChangePassword', (error as HttpErrorResponse).message);
          });
    }
    this.btnCloseResetUserPopup.nativeElement.click();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
export interface PeriodicElement {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean,
  isAdmin: boolean;
}

const ELEMENT_DATA: PeriodicElement[] = [
];
