declare var require: any;
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ErrorService, AuthenticationService } from '../_services/index';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  loginFailed: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private errorService: ErrorService) { }

  ngOnInit() {
    this.loginFailed = false;
    this.authenticationService.logout();
    this.authenticationService.PageTitle = 'Login';
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    try {
      this.loading = true;
      this.authenticationService.Login(this.model.userId, this.model.password)
        .subscribe(data => {
          this.model = data;
          if (this.model.loggedIn) {
            this.router.navigate([this.returnUrl]);
          } else {
            this.loginFailed = true;
          }
        },
        error  => {
          this.errorService.LogError('login', 'Login', (error as HttpErrorResponse).message);
        });
      this.loading = false;
    } catch (error) {
      this.errorService.LogError('login', 'Login', error.message);
    }
  }
}
