import { Injectable } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
import { Observable } from '../../../node_modules/rxjs/Observable';

import { appConfig } from '../app.config';
import {  Deal } from '../_models/index';

@Injectable()
export class DealService {

  constructor(private http: HttpClient) { }

  GetDealList(id: string,  entity: string, beginDate: string, endDate: string,  userId: string): Observable<Deal[]> {

    const queryString = 'api/deal/GetDealList/'
      + id + '/' + entity + '/' + beginDate + '/' + endDate + '/' + userId;
    return this.http.get<Deal[]>(appConfig.apiUrl + queryString);

  }
  GetDeal(type: string, entity: string, dealNumber: string, userId: string): Observable<Deal> {
    const queryString = appConfig.apiUrl + 'api/deal/GetDeal/' + type + '/' + entity + '/' + dealNumber + '/' + userId;
    return this.http.get<Deal>(queryString);
  }
  SaveDeal(deal: Deal): Observable<Deal> {
    return this.http.post<Deal>(appConfig.apiUrl + 'api/deal/SaveDeal', deal );
  }


}
