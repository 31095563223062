import { Injectable, Output, EventEmitter } from '@angular/core';
import { NonNullAssert } from '@angular/compiler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Error } from '../_models/index';
import { appConfig } from '../app.config';


@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  // isOpen = false;

  @Output() notify: EventEmitter<NonNullAssert> = new EventEmitter();
  constructor(private http: HttpClient, private authenticationService: AuthenticationService) { }

  private error: Error;
  LogError(routine: string, page: string, details: string) {
    this.error = new Error();
    this.error.userId = 'undefined';
    if (this.authenticationService.CurrentUser !== undefined) {
      this.error.userId = this.authenticationService.CurrentUser.userId;
    }
    this.error.routine = routine;
    this.error.page = page;
    this.error.url = appConfig.apiUrl + '/' + page;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
    };
    this.error.details = details;
    const body = JSON.stringify(this.error);
    this.http.post(appConfig.apiUrl + 'api/log/LogError', body, httpOptions ).subscribe( isErrorLogged => {
    if (isErrorLogged) {

      this.notify.emit();
    }
  });
  }
}
