import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { appConfig } from '../app.config';
import { User, MVUser } from '../_models/index';
import { Observable } from '../../../node_modules/rxjs/Observable';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) { }

  GetUsers(id: string): Observable<User[]> {
    return this.http.get<User[]>(appConfig.apiUrl + '/api/user/GetUsers/' + id);
  }
  GetUserById(userId: string): Observable <User> {
    return this.http.get<User>(appConfig.apiUrl + 'api/user/GetUser/' + userId);
  }
  Save(user: User): Observable<User> {
    return this.http.post<User>(appConfig.apiUrl + 'api/user/SaveUser/', user);
  }
  GetUserIp(): Observable<string> {
    return this.http.get<string>(appConfig.apiUrl + 'api/user/GetUserIp' );
  }
  GetServerId(): Observable<string> {
    return this.http.get<string>(appConfig.apiUrl + 'api/user/GetServerId');
  }
}
